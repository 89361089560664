
import { httpClient, setResponseType } from '@/app/shared/http-service';
// import { apiGlobalConstants } from '../config';
import { MEDICO_API } from "@/config";

// domain/api/medico
// const BASE_API = apiGlobalConstants.admin;

export const downloadExcel = (filename, model = null ) => {
  setResponseType('blob');
  return httpClient.post(`${model}/to-excel`, {name: filename}).then(res => {
    setResponseType('json');
    return res
  });
};

export const resetResponseType = () => {
  setResponseType('json');
};

export const suggestGlobal = (model, term = "") => {
  let filter = `nombre=${term}`;
  return httpClient.get(`${MEDICO_API}/${model}/suggest?${filter}`).then(res => res.data);
};
