<template>
  <CFooter>
    <div>
      <a href="https://balderma.com/" target="_blank">{{ COMPANY_DATA.company_name }}</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }}</span
      >
    </div>
    <div class="m-auto">
      <a :href="COMPANY_DATA.terminos_condiciones" class="px-2" target="_blank">Términos y condiciones</a> |
      <a :href="COMPANY_DATA.aviso_legal" class="px-2" target="_blank">Avisos legales</a> 
      | <a :href="COMPANY_DATA.cookies" class="px-2" target="_blank">Políticas de cookies</a>
    </div>
    <!-- <div class="">
      <span class="me-1">Desarollado por</span>
      <a href="https://www.trestristestigres.com" target="_blank"><b>tres</b>tristes<b>tigres</b></a>
    </div> -->
  </CFooter>
</template>

<script>
import { COMPANY_DATA } from '@/config';
export default {
  name: 'AppFooter',
  setup() {
    return {
      COMPANY_DATA
    }
  },

}
</script>
