import $store from '@/store'
import TimeAgo from 'javascript-time-ago'
import es from 'javascript-time-ago/locale/es'

TimeAgo.addDefaultLocale(es);

// Create formatter
const timeAgo = new TimeAgo('es-ES')

export const dateFormatTimeAgo = (date) => {

  let date1 = new Date(date);
  return timeAgo.format(date1);
}

export const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
    .replace(/[\u0300-\u036f]/g, ''); // Replace accentuated characters
}

export function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1))
    let temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}
export const SqlFormatToFecha2 = (entrada) => {

  if(entrada == null)
    return '__/__/____ __:__:__';

  if(entrada != null) {
      // YYYY-MM-DD => dd/mm/aaaa hh:mm:ss
    let anyo = '' + entrada[0] + entrada[1] + entrada[2] + entrada [3];
    let mes = '' + entrada[5] + entrada[6];
    let dia = '' + entrada[8] + entrada[9];
    let hora = '' + entrada[11] + entrada[12];
    let minuto = '' + entrada[14] + entrada[15];
    let segundo = '' + entrada[17] + entrada[18];
    return '' + dia + '/' + mes + '/' + anyo + ' ' + hora + ':' + minuto + ':' + segundo;
  }
}
export const generarClaveFriendly = (str) => {
  let resultado = "";

  for (let i = 0; i < str.length; i++) {
    if(str[i] == "ñ" || str[i] == "Ñ") {
      resultado += 'n';
    }
    else if(str[i] == 'á' || str[i] == 'Á' || str[i] == 'à' || str[i] == 'À') {
      resultado += "a";
    }
    else if(str[i] == 'é' || str[i] == 'É' || str[i] == 'è' || str[i] == 'È') {
      resultado += "e";
    }
    else if(str[i] == 'í' || str[i] == 'Í' || str[i] == 'ì' || str[i] == 'Ì' || str[i] == 'ï' || str[i] == 'Ï') {
      resultado += "i";
    }
    else if(str[i] == 'ó' || str[i] == 'Ó' || str[i] == 'ò' || str[i] == 'Ò') {
      resultado += "o";
    }
    else if(str[i] == 'ú' || str[i] == 'Ú' || str[i] == 'ù' || str[i] == 'Ù' || str[i] == 'ü' || str[i] == 'Ü') {
      resultado += "u";
    }
    else if(str[i] == 'ç' || str[i] == 'Ç') {
      resultado += "c";
    }
    else if((str[i] >= 'a' && str[i] <= 'z') || (str[i] >= 'A' && str[i] <= 'Z') || (str[i] >= '0' && str[i] <= '9')) {
      resultado += String(str[i]).toLowerCase();
    }
    else {
      resultado += '-';
    }
  }
  return resultado;

}

export const copyToClipboard = async (url) => {
  try {
    await navigator.clipboard.writeText(url);
  } catch (e) { console.log(e) }
}

export const formatearValor = (value) => {
  if(value != null && value.length > 0) {
    value = value.toString().replace(',', '.');
    let resultado = '';

    for (let i of value) resultado+= i == '.' ? '.' : i >= '0' && i <= '9' ? i : '';
    return resultado;
  }
}

export const formatBoolean = (value) => {
  if (value === 1 || value === 0)
    return value == 1

  return value == true ? 1 : 0;
}

export const generatePassword = () => {
  let length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export const validEmail = (email) => {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const validDNI = (value) => {
  let validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
  let nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
  let nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
  let str = value.toString().toUpperCase();

  if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

  let nie = str
      .replace(/^[X]/, '0')
      .replace(/^[Y]/, '1')
      .replace(/^[Z]/, '2');

  let letter = str.substr(-1);
  let charIndex = parseInt(nie.substr(0, 8)) % 23;

  if (validChars.charAt(charIndex) === letter) return true;

  return false;
}
export const setPagination = (data = null)  => {
  let pagination = {
    rows: 0,
    total: 0,
    pagina_actual: 1,
    last_page: 1,
    currentPage: 1,
    perPage: 25,
  }
  if (data) {
    pagination.rows = data.total;
    pagination.pagina_actual = data.current_page;
    pagination.currentPage = data.current_page;
    pagination.last_page = data.last_page;
    pagination.total = data.total;
  }

  return pagination;
};

export const capitalize = (str) => {
  let lower = str.toLowerCase();
  if (lower == 'valencia') lower = lower+ '/València';
  return str.charAt(0).toUpperCase() + lower.slice(1);
}

export const setFilters = (component, data = null) => {

  const filter = $store.state.cache.filters[component];
  if (!filter) return null

  return data !== null ? filter[data] : filter
}

export const setStoreData = (component) => {
  const filter = $store.state.datos.datosApi[component];
  return filter ?? null;
}

export const formError = (errors, value)  => {
  return Object.keys(errors).includes(value)
}

export const refreshTableData = (data, item) => {
  if (item) {
    let index = data.indexOf(data.find(i => i.id === item.id))
    data.splice(index, 1)
  }
  data = [...data]

  return data
}

export const eliminarParrafosVacios = (mensaje, empty = '<p><br></p>') => {
  let mensajecopy = mensaje.trim();

  while(mensajecopy.endsWith(empty)) {
    mensajecopy = mensajecopy.substring(0, mensajecopy.length - empty.length);
  }

  return mensajecopy;
  // return mensajecopy.replaceAll(empty, '<p></p>');
}

export const createBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (event) => {
      resolve(event.target.result)
    }
    reader.readAsDataURL(file);
  })
}

export const convertSizeFileToMb = (fileSizeBits) => {
  if (fileSizeBits)
    return `${(fileSizeBits / (1024 ** 2)).toFixed(2)} MB`;
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

/*
It's more correct to use the "Kib", etc. abbreviations when using base 1024, because "KB", etc. actually refers to base 1000.
If you wish to calculate the value to base 1000, then just replace 1024 with 1000 in this function, and use the appropriate units (kB, MB, etc.)
This solution includes a decimal point and a tenths-place digit if presenting less than ten of KB or greater units
*/
export const niceBytes = (x) => {

  const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let l = 0, n = parseInt(x, 10) || 0;

  while(n >= 1024 && ++l){
      n = n/1024;
  }

  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

// Deep clone
// Cuidado: si el objeto que le pasemos tiene alguna función, no se copiará.
export const clone = (obj) => JSON.parse(JSON.stringify(obj));



// ***  Formatear números  ***********//
/**
 * Al igual que con las fechas, nos interesa convertir los números según dónde se encuentren nuestros usuarios.
 * Así, podremos mostrar separadores de miles y de decimales con puntos o comas sin preocuparnos.
 */
export const formatNum = (num) => Intl.NumberFormat().format(num).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
// 12345.6789 --> "12.345,678"

// const locale = 'en';
// const options = { style: 'currency', currency: 'GBP', maximumFractionDigits: 2};
// const formatNum = (num) => {
//   return Intl.NumberFormat(locale, options).format(num);
// };
// 12345.6789 --> "£12,345.68


// ***  Formatear FECHAS  ***********//

// evitar error new Date NaN en Safari & IE
const fixDateTimeForAllBrowsers = dateString => dateString.toString().replace(" ", "T");
const fixDateForAllBrowsersTest = dateString => dateString.toString().replace(/-/g, '/');  //(" ", "T"); //


export const formatDateFull = (date) => new Date(fixDateTimeForAllBrowsers(date)).toLocaleString();
// "29/1/2021 9:57:51"

// const formatDate = (date) => new Date(date).toLocaleDateString();
// "29/1/2021"

export const formatTime = (date) => new Date(fixDateTimeForAllBrowsers(date)).toLocaleTimeString();
// "9:57:51"

export const formatHour = (value) => {

  let date = new Date(fixDateTimeForAllBrowsers(value))
  let hour = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  let minutos = (minutes < 10) ? '0' + minutes : minutes
  let segundos = (seconds < 10) ? '0'+ seconds : seconds

  return `${hour}:${minutos}:${segundos}`
}
//HH:mm'

// Evitar 1 cifra. Por defecto, los números de una cifra se muestran sin un cero delante
const locale = navigator.language;

export const formatDateShort = (date) => {
  const options = {day: '2-digit', month: '2-digit', year: '2-digit'};
  return new Date(fixDateTimeForAllBrowsers(date)).toLocaleDateString(locale, options);
};
// "29/01/21"

export const formatDateCustom = (date, separator = '/') => {
  if (!date) return date;

  const options = {day: '2-digit', month: '2-digit', year: 'numeric'};
  // replaceAll('/', separator)
  return new Date(fixDateTimeForAllBrowsers(date)).toLocaleDateString(locale, options).split('/').join(separator)
}
// "29-1-2021" // separator = '-'

export const formatDateToTime = (date) => {
  return moment(date).format('HH:mm')
}

export const formatDateBasic = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

export const getAge = (date) => {
  const now = moment();
  const birth_date = moment(date);

  return now.diff(birth_date, 'years');
}


export const formatDateISOCustom = (date, separator = '-') => {
  const options = {day: '2-digit', month: '2-digit', year: 'numeric'};

  let arrayDate = new Date(date).toLocaleDateString(locale, options).split('/')
  let isoDate = [ arrayDate[2], arrayDate[1], arrayDate[0] ]

  return isoDate.join(separator)
}
// "2021-05-28" // separator = '-'

export const formatDateTimeCustom = (date, separator = '/') => {
  const options = {day: '2-digit', month: '2-digit', year: 'numeric'};
  return `${new Date(fixDateTimeForAllBrowsers(date)).toLocaleDateString(locale, options).split('/').join(separator)}  ${new Date(fixDateTimeForAllBrowsers(date)).toLocaleTimeString()}`
  // "29-1-2021 9:57:51" // separator = '-'
}

export const todayISODate = () => new Date().toISOString().slice(0, 10);
// 2021-05-28

export const formatISODateTime = (date) => {
  return `${new Date(fixDateTimeForAllBrowsers(date)).toISOString().slice(0, 10)}  ${new Date(fixDateTimeForAllBrowsers(date)).toLocaleTimeString()}`
  // "2021-05-28 9:57:51"
}

export const formatISODate = (date) => {
  return new Date(fixDateTimeForAllBrowsers(date)).toISOString().slice(0, 10)
  // "2021-05-28 9:57:51"
}

// los meses van de 0(enero) a 11(diciembre)
export const getFirstDayOfMonth = (year, month) => {
  return new Date(year, month, 1);
}

export const now = () => Date.now();

// *** END Formatear FECHAS  ***********//

export const mimeFilesIcons= (mime) => {
  return ['ai', 'audio', 'box', 'csv', 'eps','excel', 'exe', 'flash', 'gdoc', 'gdocs',
    'gform', 'gpress', 'gsheet', 'html', 'image', 'keynote', 'mp4', 'pdf', 'ppt', 'psd',
    'rtf', 'slide', 'stypi', 'txt', 'video', 'visio', 'webwx', 'xml', 'zip'
  ].includes(mime);
}

export  const getIconFile = (mime) => {

  const ICON_MIME_TYPE = {
    'audio': 'audio',
    'video': 'video' ,
    'application': 'gdoc',
    'image': 'image',
    'text': 'unknown',
  }
  const DEFAULT = 'unknown';

  return ICON_MIME_TYPE[mime] || DEFAULT
}

/*export const formatNum = (nStr) => {
  if(!nStr) return 0;

	nStr = nStr.toString().replace('.', ',');

  return nStr.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
}*/

export const blobToJson = async (res) => {
  res.text()
  const text = await res.text();

  return JSON.parse(text);
}

export const getEdad = (dateString) => {

  // const fechaActual = new Date();
  // const edad = fechaActual.getFullYear() -  dateString.getFullYear();
  // return edad;

  const hoy = new Date();
  let fechaNacimiento = new Date(dateString);
  let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
  let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();

  if (diferenciaMeses < 0 || (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
    edad--
  }
  return edad;
}


export const getColorEstadoAsociadoABeca = (estado) => {

  /*
  {id: 357, nombre: "Contratado"},
  {id: 356, nombre: "Pendiente"},
  {id: 359, nombre: "Renuncia"},
  {id: 358, nombre: "Rescindido"},
  */

  const ESTADOS_ASOCIADO = {
    'Contratado': 'success',
    'Pendiente': '' ,
    'Renuncia': 'danger',
    'Rescindido': 'danger',
  }
  const DEFAULT = '';

  return ESTADOS_ASOCIADO[estado] || DEFAULT
}

export const querySelector = (query) => {
  return document.querySelector(query);
}

export const querySelectorAll = (query) => {
  return Array.from(document.querySelectorAll(query));
}

export const splitQuery = (query) => {
  let filtros = [];

  console.log(query)
  query.split('&').forEach(filtro => {
    const filtroSeparado = filtro.split('=');
    console.log(filtroSeparado)

    if(filtroSeparado.length > 1){
      filtros.push({
        filtro: filtroSeparado[0],
        valor: filtroSeparado[1]
      })
    }
  })

  return filtros;
}

export const paginaAnterior = () => {
  history.back();
}

export const removeAccents = (str) => {
  let chars={
		"á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
		"à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u",
		"Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
		"À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U"}
  let expr=/[áàéèíìóòúù]/ig;

  return str.toString().replace(expr, (e) => chars[e]);
}

function pad2(n) { // return 2-character string
  return (n < 10 ? '0' : '') + n;
}

export const diasHabiles = (dias) => {
  let d = new Date();
  let i=0;

  while (i<dias) {
    d.setTime(d.getTime()+24*60*60*1000);
    if (d.getDay() != 6 && d.getDay() !== 0)
      i++;
  }
  return pad2(d.getMonth() + 1) + "/" +
        pad2(d.getDate()) + "/" +
        d.getFullYear();
}

export const isEmptyOrNull = (str) => {
  return str === null || str === undefined || str?.trim() === '';
}



export const expedienteLabelStatus = (status) => {

  const STATUS_EXPEDIENTE_ID = {
    21: 'Aceptado por cliente' ,
    17: 'Borrador' ,
    16: 'Creado' ,
    20: 'Diagnosticado' ,
    45: 'Notificado' ,
    18: 'Pendiente diagnóstico' ,
    19: 'Pendiente diagnóstico (parcial)' ,
  }
  const DEFAULT = status;

  return STATUS_EXPEDIENTE_ID[status] || DEFAULT
}


/**
 
Orden de estados de expediente

  Creado
  Pendiente diagnóstico (Lo ha enviado el centro al médico)
  Pendiente diagnóstico parcial (el médico ha valorado alguna lesión pero no todas)
  Notificado (Expediente enviado al paciente)
  Diagnosticado
  Aceptado por cliente

A partir de notificado ya no debe aparecer el botón para enviar

 */